<template>
  <!-- 水电抄数 -->
  <div class="my_meterReading">
    <van-nav-bar :title="'水电抄数登记'"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
    </van-nav-bar>
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="query_box">
      <div class="area_box"
           @click="showFloorsPicker = true">
        <van-field v-model="areaTxt"
                   readonly />
        <van-icon name="arrow" />
      </div>
      <div class="year_box">
        <div class="year_mon">
          <div class="icon_box"
               @click="arrowsYear('left')">
            <van-icon name="arrow-left" />
          </div>
          <div class="year_btn">
            <div class="year">{{ displayedDate }}</div>
          </div>
          <div class="icon_box"
               @click="arrowsYear('right')">
            <van-icon name="arrow" />
          </div>
        </div>
        <van-icon name="notes-o"
                  @click="showYearPicker = true" />
      </div>
    </div>
    <div class="table_box"
         style="overflow: auto;">
      <table ref="table"
             class="table">
        <!-- 表头 -->
        <tr class="top">
          <th v-for="(item_th, index_th) in tableHeadList"
              :key="index_th">
            <div class="title"
                 :style=" item_th.gridwidth  ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px' ">
              {{ item_th.chnname }}
            </div>
          </th>
          <th>
            <div class="title"
                 style="width:40px;">
              登记
            </div>
          </th>
        </tr>
        <!--展示列表数据 -->
        <tr class="cont"
            v-for="(item_tr, index_tr) in tableData"
            :key="index_tr">
          <td v-for="(item_td, index_td) in tableHeadList"
              :key="index_td">
            <div class="comm_box">
              <div class="boolean_box"
                   v-if="item_td.datatype == 'checkbox'">
                <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
              </div>
              <div class="input_box"
                   v-else-if="item_td.datatype == 'num'">
                <van-field v-model="item_tr[item_td.fieldname]"
                           :placeholder="item_td.chnname" />
              </div>
              <div v-else>{{ item_tr[item_td.fieldname] }}</div>
            </div>
          </td>
          <td>
            <div class="edit_td">
              <van-button type="info"
                          size="mini"
                          @click.stop="checkDetails(item_tr)">详情</van-button>
            </div>
          </td>
        </tr>
        <tr class="nodata"
            v-if="tableData.length == 0">
          <td align="center"
              :colspan="tableHeadList.length + 1">
            暂无数据
          </td>
        </tr>
      </table>
    </div>

    <!-- 选择年月 -->
    <van-popup v-model="showYearPicker"
               position="bottom"
               round>
      <van-datetime-picker v-model="yymm"
                           type="year-month"
                           :title="$t('schedulingSettings.sesTxt4')"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onYearConfirm"
                           @cancel="showYearPicker = false" />
    </van-popup>
    <!-- 选择楼层数据 -->
    <van-popup v-model="showFloorsPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :loading="loading"
                  :title="'区域选择'"
                  :columns="floorList"
                  :value-key="'keyname'"
                  @confirm="onFloorsPickerConfirm"
                  @cancel="showFloorsPicker = false">
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import { getCommTreeData } from "@api/wxjx.js";
import { getWxMonthDormCostInput } from "@api/wx.js";
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    const query = this.$route.query
    let areaTxt = query.areaData ? query.areaData : ''
    let floorid = query.floorid
    let yymm = new Date()
    if (query.yymm)
    {
      yymm = new Date(new Date(query.yymm.substring(0, 4) + '-' + query.yymm.substring(4, 7)))
    }
    return {
      userInfo,
      areaTxt,
      yymm,
      floorid,
      displayedDate: '',
      showYearPicker: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      floorList: [], // 楼层区域
      showFloorsPicker: false,
      loading: true,
      tableHeadList: [],
      tableData: [],
    }
  },
  created () {
    const query = this.$route.query
    this.areaTxt = query.areaData ? query.areaData : ''
    this.floorid = query.floorid
    this.yymm = new Date()
    if (query.yymm)
    {
      this.yymm = new Date(query.yymm.substring(0, 4) + '-' + query.yymm.substring(4, 7))
    }
    const year = new Date(this.yymm).getFullYear()
    const mon = new Date(this.yymm).getMonth() + 1
    this.displayedDate = year + this.$t('schedulingSettings.sesTxt11') + mon + this.$t('schedulingSettings.sesTxt12')
    this.getWxMonthDormCostInputData()
  },
  watch: {
    // 区域弹窗
    showFloorsPicker (n) {
      if (n)
      {
        this.getTreeData();
      } else
      {
        this.loading = true
      }
    }
  },
  methods: {
    // 查看详情
    checkDetails (it) {
      this.$router.push({
        path: '/meterReadDetail', query: { from: this.$route.query.from, areaData: this.areaTxt, floorid: this.floorid, yymm: parseTime(this.yymm, '{y}{m}'), item: it.dormno, itemid: it.autoid }
      })
    },
    // 左右切换月份
    arrowsYear (val) {
      let year = this.yymm.getFullYear()
      let month = this.yymm.getMonth()
      if (val == 'left')
      {
        month = month - 1
      } else
      {
        month = month + 1
      }
      this.yymm = new Date(year, month, 1);
      this.displayedDate = this.yymm.getFullYear() + this.$t('schedulingSettings.sesTxt11') + (this.yymm.getMonth() + 1) + this.$t('schedulingSettings.sesTxt12')
      this.getWxMonthDormCostInputData()
    },
    // 获取指定月份某个楼层所有宿舍抄数记录
    getWxMonthDormCostInputData () {
      getWxMonthDormCostInput({
        yymm: parseTime(this.yymm, '{y}{m}'),
        username: this.userInfo.username,
        floorid: this.floorid
      }).then(res => {
        this.tableHeadList = res.fieldlist.filter(it => { return it.gridshow == 1 || it.gridshow == true })
        this.tableHeadList.forEach(el => {
          el.datatype = 'str'
        });
        this.tableData = res.data
      })
    },
    // 选择年月
    onYearConfirm (value) {
      if (value)
      {
        this.yymm = value
        const year = this.yymm.getFullYear()
        const mon = this.yymm.getMonth() + 1
        this.displayedDate = year + this.$t('schedulingSettings.sesTxt11') + mon + this.$t('schedulingSettings.sesTxt12')
        this.getWxMonthDormCostInputData()
      }
      this.showYearPicker = false
    },
    // 年月格式化
    formatter (type, val) {
      if (type === 'year')
      {
        return `${val}年`;
      } else if (type === 'month')
      {
        return `${val}月`;
      }
      return val;
    },
    // 水电抄数-选中楼层回调
    onFloorsPickerConfirm (val, keys) {
      this.areaTxt = val.join('/')
      const floor = this.floorList[keys[0]].children[keys[1]].children[keys[2]]
      this.floorid = this.floorList[keys[0]].children[keys[1]].children[keys[2]].autoid
      this.getWxMonthDormCostInputData()
      this.showFloorsPicker = false
    },
    // 获取数据区域楼栋楼层信息接口
    getTreeData () {
      getCommTreeData({
        moduleno: 908,
        treename: 'dormtree',
        username: this.userInfo.username,
        fieldname: 'wx_dorm_area',
        editstate: 3,
        formstr: ''
      }).then(res => {
        let list = res.data || []
        // 只需要1,2,3级数据
        list = list.filter(it => { return it.levelid != 0 && it.levelid != 4 })
        let map = {}, node, tree = [], i;
        for (i = 0; i < list.length; i++)
        {
          if (list[i].parentid == 0)
          {
            list[i].parentid = null
          }
          map[list[i].autoid] = i; // 初始化映射
          if (list[i].levelid != 3)
          {
            list[i].children = []; // 初始化children数组
          }
        }
        for (i = 0; i < list.length; i++)
        {
          node = list[i];
          if (node.parentid !== null)
          {
            // 如果当前节点有父节点，则找到父节点的位置并将当前节点添加到父节点的children数组中
            list[map[node.parentid]].children.push(node);
          } else
          {
            // 如果当前节点没有父节点，则将其添加到树的顶层
            tree.push(node);
          }
        }
        this.floorList = tree
        // 去掉没有楼栋的区域
        let newDong = []
        this.floorList.forEach(it => {
          if (it.children.length != 0)
          {
            newDong.push(it)
          }
        })
        this.floorList = newDong
        // 去掉没有楼层的楼栋
        this.floorList.forEach(it => {
          let newChilds = []
          it.children.forEach(item => {
            if (item.children.length != 0)
            {
              newChilds.push(item)
            }
          });
          it.children = newChilds
        });
        // console.log(tree, 'addChildren');
        this.loading = false
      })
    },
    // 返回
    onClickLeft () {
      if (this.$route.query.from == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/personCen')
      }
    }
  }

}
</script>

<style lang="less" scoped>
.my_meterReading {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .query_box {
    height: 120px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background: #54a4ec;
    color: #ffffff;
    .area_box {
      display: flex;
      align-items: center;
      font-size: 28px;
      border: 1px solid;
      border-radius: 20px;
      .van-cell {
        padding: 10px 20px;
        width: 300px;
        border-radius: 20px;
        background-color: unset;
        /deep/.van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #ffffff;
            }
          }
        }
      }
      .van-cell::after {
        border: unset;
      }
      .van-icon {
        margin-right: 20px;
      }
    }
    .year_box {
      display: flex;
      align-items: center;
      flex: 1;
      // height: 120px;
      // padding: 20px 0;
      // background: #ffffff;
      .year_mon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          // width: calc((100% - 40px) / 3);
          margin: 0 10px;
          // color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          .year {
            padding: 10px 0;
          }
        }
      }
      .van-icon-notes-o {
        font-size: 40px;
        font-weight: bold;
        // margin-right: 30px;
      }
    }
  }
  .table_box {
    height: calc(100% - 120px - 1.22667rem);
    .table {
      width: 100%;
      border-spacing: 0px;

      tr {
        td:nth-child(1) {
          border-left: 2px solid #dcdfe6;
        }

        th,
        td {
          padding: 6px 10px;
          text-align: center;
          border-bottom: 2px solid #dcdfe6;
          border-right: 2px solid #dcdfe6;

          .comm_box {
            display: flex;
            justify-content: center;

            .boolean_box {
              .van-checkbox {
                justify-content: center;

                /deep/.van-checkbox__icon--checked {
                  .van-icon {
                    color: #fff !important;
                    background-color: #1989fa !important;
                    border-color: #1989fa !important;
                  }
                }

                /deep/.van-checkbox__icon--disabled .van-icon {
                  background-color: #fff;
                }
              }
            }

            .input_box {
              width: 150px;

              .van-cell {
                font-size: 24px;
                border: 2px solid #d2d2d2;
                border-radius: 10px;
                padding: 8px 16px;
              }
            }
          }
        }
      }

      .top {
        position: sticky;
        top: 0;
        font-size: 28px;
        background: #e9f3fd;
        height: 64px;

        th:nth-child(1) {
          border-left: 2px solid #dcdfe6;
        }

        th {
          border-top: 2px solid #dcdfe6;
        }
      }

      .cont {
        height: 64px;
        font-size: 24px;
      }

      .cont:nth-child(2n-1) {
        background: #f5f5f5;
      }

      .nodata {
        td {
          font-size: 28px;
        }
      }
    }
  }
}
</style>